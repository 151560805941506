import { EmailRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, InputAdornment, Button, FormControl, IconButton, InputLabel, OutlinedInput, Alert, CircularProgress, Stack, Link, Typography, FormControlLabel, Checkbox, Divider, Select, MenuItem, LinearProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, FormHelperText, ListItemIcon, ListItemText, styled, ListItemButton, ListItem } from "@mui/material";
import { CardElement, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe, PaymentIntentResult, Stripe, StripeCardElementOptions } from "@stripe/stripe-js";
import axios from "axios";
import bind from "bind-decorator";
import { autorun, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha"
import { PublicService } from "../helpers/publicService";
import { gateUsers, isEmail, Item, requestStateType } from "../helpers/utils";
import { GetPhoneNumber } from "./getPhoneNumberComponent";
import { CommonAppTitle } from "../helpers/utilComponents";
import { WaitingListDialog } from "./waitingListDialog";

import US from 'country-flag-icons/react/3x2/US'
import GB from 'country-flag-icons/react/3x2/GB'
import CH from 'country-flag-icons/react/3x2/CH'

export const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

export interface ILoginState {
}

export const passwordLabel = 'Password (10 to 30 characters)'

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    style: {
        base: {
            // color: "#32325d",
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"',
            // fontSmoothing: "antialiased",
            fontSize: "16px",
            // "::placeholder": {
            //   color: "#aab7c4",
            // },
            // textAlign: "justify",
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
    hidePostalCode: true
};

interface IProductSelectProps {
    publicService: PublicService
    store: SignupStore
}
interface IProductSelectDropDownProps {
    publicService: PublicService
    store: SignupStore
}
interface IPaymentProps {
    publicService: PublicService
    store: SignupStore
}

@observer
class ProductSelectDropdown extends React.Component<IProductSelectDropDownProps> {
    @observable products: any[] | undefined
    @observable error: string | undefined

    constructor(props: IProductSelectDropDownProps) {
        super(props)
        makeObservable(this)
    }

    componentDidMount(): void {
        const { coid } = Object.fromEntries(new URLSearchParams(document.location.search));
        this.getProducts(coid)
    }

    private async getProducts(selectedProductId: string | undefined) {
        try {
            const res = await this.props.publicService.getAvailableProducts()
            runInAction(() => {
                this.products = res
                let selectedIndex = 0
                if (selectedProductId) {
                    selectedIndex = this.products.findIndex((v) => v.metadata.coid === selectedProductId)
                }
                this.props.store.product = this.products[selectedIndex]
            })
        } catch {
            runInAction(() => this.error = 'Could not get products')
        }
    }

    render() {
        if (this.products && this.products.length > 0) {
            const menuItems = this.products?.map((item, i) => {
                return (
                    <MenuItem key={i} value={item}>{item.name}</MenuItem>
                );
            })
        return (<React.Fragment>
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Choose product</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.props.store.product ?? ''}
                label="Choose product"
                onChange={ (ev) => runInAction(() => this.props.store.product = ev.target.value) }
            >
            {menuItems}
            </Select>
            </FormControl>
        </React.Fragment>)
        } else {
            if (this.error) {
                return (<Typography><p>An error occured</p></Typography>)
            } else {
                return (<LinearProgress></LinearProgress>)
            }
        }
    }
}

interface ICountryCodeSelectProps {
    publicService: PublicService
    store: SignupStore
}

@observer
class CountryCodeSelect extends React.Component<ICountryCodeSelectProps> {
    @observable countryCodes: any[] | undefined
    @observable error: string | undefined

    constructor(props: ICountryCodeSelectProps) {
        super(props)
        makeObservable(this)
    }

    componentDidMount(): void {
        const { ccid } = Object.fromEntries(new URLSearchParams(document.location.search));
        this.getProducts(ccid)
    }

    private async getProducts(selectedCountryCode: string | undefined) {
        try {
            const res = await this.props.publicService.getCountryCodes()
            runInAction(() => {
                this.countryCodes = res.sort((a,b) => a.priority - b.priority)
                let selectedIndex = 0
                if (selectedCountryCode) {
                    selectedIndex = this.countryCodes.findIndex((cc) => cc.code === selectedCountryCode)
                    if (selectedIndex === -1) {
                        selectedIndex = 0
                    }
                }
                this.props.store.countryCode = this.countryCodes[selectedIndex].code
            })
        } catch {
            runInAction(() => this.error = 'Could not get products')
        }
    }

    render() {
        if (this.countryCodes && this.countryCodes.length > 0) {
            const menuItems = this.countryCodes?.map((item, i) => {
                const Flag = () => {
                    switch (item.code) {
                        case 'US': return <US/>
                        case 'GB': return <GB/>
                        case 'CH': return <CH/>
                        default: return <></>
                    }
                }
                return (
                    <MenuItem key={i} value={item.code}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                        }}>
                            <ListItemIcon style={{minWidth: '28px', marginRight: '1em'}} >
                                <Flag />
                            </ListItemIcon>
                            <ListItemText style={{marginTop: '0px', marginBottom: '0px'}} primary={item.name}/>
                        </div>
                    </MenuItem>
                );
            })
        return (<React.Fragment>
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Number country code</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.props.store.countryCode ?? ''}
                label="Number country code"
                onChange={ (ev) => runInAction(() => this.props.store.countryCode = ev.target.value) }
            >
            {menuItems}
            </Select>
            </FormControl>
        </React.Fragment>)
        } else {
            if (this.error) {
                return (<Typography><p>An error occured</p></Typography>)
            } else {
                return (<LinearProgress></LinearProgress>)
            }
        }
    }
}

@observer
class ProductSelect extends React.Component<IProductSelectProps> {

    renderChoosePhoneNumber() {
        if (process.env.REACT_APP_ASSIGN_RANDOM_PHONE_NUMBER === 'true') {
            return <>
                <CountryCodeSelect store={this.props.store} publicService={this.props.publicService}></CountryCodeSelect>
                <Typography variant="body2" align="center" justifyContent={"center"}>A random phone number will be assigned to you after you subscribe.</Typography>
                <Typography variant="body2" align="center" justifyContent={"center"}><b>Calls only number, SMS functionality is disabled!</b><br/>You will <b>not</b> be able to send or receive text messages (SMS).<br/>You will still be able to place and receive phone calls.</Typography>
                {/* <Typography variant="body2" align="center" justifyContent={"center"}>Please note that although the numbers we provide can receive SMS, we cannot guarantee that you will be able <br/> to use them with services like Whatsapp or for 2FA. See more details <a href="https://support.twilio.com/hc/en-us/articles/223133447-Not-Receiving-Incoming-SMS-and-MMS-Messages-on-Twilio-Phone-Number">here</a></Typography> */}
                <FormControlLabel control={<Checkbox checked={this.props.store.okNoSms} onChange={(ev) => runInAction(() => this.props.store.okNoSms = ev.target.checked)} size="medium" />}
                    label="I understand and agree with this limitation" />
            </>
        }
        return <GetPhoneNumber store={this.props.store} publicService={this.props.publicService}/>
    }

    render() {
        return (<React.Fragment>
            <ProductSelectDropdown store={this.props.store} publicService={this.props.publicService}/>
            { this.props.store.product?.metadata.hasPhoneNumber == '1' && this.renderChoosePhoneNumber()}
        </React.Fragment>)
    }
}

@observer
class PaymentInfo extends React.Component<IPaymentProps> {

    @observable hasPromoCode: boolean = false;
    unappliedPromoCode: string | null = null;

    constructor(props: IPaymentProps) {
        super(props)
        makeObservable(this)
    }

    render() {
        return <>
        { this.props.store.currency !== undefined && this.props.store.price !== undefined && <Typography variant="h5" align="center" justifyContent={"center"} color="#007FFF">{`${this.props.store.currency?.toUpperCase()} ${(this.props.store.price/100).toFixed(2)}/month`}</Typography> }
        <FormControl fullWidth>
            <InputLabel htmlFor="outlined-adornment-fullname">{'Cardholder Name'}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-fullname"
                    type='text'
                    autoComplete='off'
                    label={'Cardholder Name'}
                    onChange={(evt) => runInAction(() => this.props.store.name = evt.target.value)}
                    fullWidth />
        </FormControl>
        <Elements stripe={stripePromise}>
            <ElementsConsumer>
            {({stripe, elements}) => {
                this.props.store.stripe = stripe
                this.props.store.elements = elements;
                return <CardElement className="card-element" options={CARD_ELEMENT_OPTIONS} onChange={(ev) => {
                    runInAction(() => this.props.store.cardComplete = ev.complete);
                }} />
            }}
            </ElementsConsumer>
        </Elements>
        <FormControlLabel componentsProps={{ typography: { fontSize: '8' } }} control={<Checkbox onChange={(ev) => runInAction(() => this.hasPromoCode = ev.target.checked)} size="medium" />}
        label="I have a promo code" />
        {this.hasPromoCode &&
            <Box display="flex">
                <FormControl>
                    <InputLabel htmlFor="outlined-adornment-promocode">{'Promo code'}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-promocode"
                            type='text'
                            autoComplete='off'
                            label={'Promo code'}
                            onChange={(evt) => runInAction(() => this.unappliedPromoCode = evt.target.value)}
                            />
                </FormControl>
                <Button sx={{ marginLeft: "auto" }} onClick={(ev) => this.props.store.promoCode = this.unappliedPromoCode} >Apply</Button>
            </Box>
        }
        </>
    }
}

export class SignupStore {

    stripeCustomerId: string | undefined = undefined
    @observable cardComplete: boolean = false;
    @observable product: any | undefined;
    @observable phoneNumber: string | undefined;
    @observable email: string | undefined;
    @observable password: string | undefined;
    @observable name: string | undefined;
    @observable promoCode: string | null = null;
    @observable subscriptionId: string | undefined = undefined
    stripe: Stripe | null = null;
    elements: any;
    @observable price: number | undefined = undefined
    @observable currency: string | undefined = undefined
    @observable paymentIntentResult: PaymentIntentResult | undefined = undefined
    @observable accountCreated: boolean = false;
    @observable okNoSms: boolean = false;
    @observable countryCode: string = 'US'

    @computed get readyToLogin(): boolean {
        return (this.paymentIntentResult && this.paymentIntentResult.paymentIntent && this.paymentIntentResult.paymentIntent.status === 'succeeded') || this.accountCreated
    }

    @computed get phoneNumberValid(): boolean {
        if (this.allowUndefinedPhoneNumber) {
            return true
        }

        if (this.product && this.product?.metadata.hasPhoneNumber == '1') {
            return this.phoneNumber !== undefined
        }
        return true
    }

    @computed get ready() {
        return this.stripeCustomerId && this.subscriptionId 
            && this.email && isEmail(this.email)
            && this.password && this.password.length >= 10 
            && this.cardComplete && this.product && this.phoneNumberValid && !!this.name 
            && ((this.product.metadata.hasPhoneNumber === '1' && this.okNoSms) || (this.product.metadata.hasPhoneNumber === undefined))
    }

    // @computed get productCurrency(): string | undefined {
    //     return this.product?.setPrice.currency
    // }

    // @computed get productPrice(): string | undefined {
    //     if (this.product) {
    //         return (this.product.setPrice.unit_amount_decimal/100).toFixed(2);
    //     } else {
    //         return undefined
    //     }
    // }

    constructor(public readonly allowUndefinedPhoneNumber: boolean) {
        makeObservable(this);
        autorun(() => {
            if (!(this.product && this.product?.metadata.hasPhoneNumber === '1')) {
                runInAction(() => this.phoneNumber = undefined)
            }
        })
    }
}

@observer
class LoginDetails extends React.Component<{ store: { email: string | undefined, password: string | undefined }}> {

    @observable showPassword: boolean = false
    @observable hasPasswordError: boolean = false
    @observable errorHelperText: string = ''

    constructor(props: { store: { email: string | undefined, password: string | undefined }}) {
        super(props)
        makeObservable(this)
        autorun(() => {
            if (this.props.store.password && this.props.store.password != '' && (this.props.store.password.length < 10 || this.props.store.password.length > 30)) {
                runInAction(() => {
                    this.hasPasswordError = true;
                    this.errorHelperText = 'Password length must be between 10 and 30 characters';
                })
            } else {
                runInAction(() => {
                    this.hasPasswordError = false;
                    this.errorHelperText = '';
                })
            }
        })
    }

    render() {
        return (<React.Fragment>
            <FormControl variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email"
                                    type='text'
                                    autoComplete='off'
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton edge="end" disabled>
                                            <EmailRounded />
                                        </IconButton>
                                    </InputAdornment>}
                                    label="Email"
                                    autoFocus
                                    onChange={(evt) => runInAction(() => this.props.store.email = evt.target.value.trim().toLowerCase())} />
                            </FormControl>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">{passwordLabel}</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={this.showPassword ? 'text' : 'password'}
                                    error={this.hasPasswordError}
                                    autoComplete='off'
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => runInAction(() => this.showPassword = !this.showPassword)}
                                            onMouseDown={(event) => event.preventDefault()}
                                            edge="end"
                                            tabIndex={-1}
                                        >
                                            {this.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>}
                                    label={passwordLabel}
                                    onChange={(evt) => runInAction(() => this.props.store.password = evt.target.value)}
                                    fullWidth />
                                    {
                                        this.errorHelperText && 
                                        <FormHelperText error id="password-error">
                                            {this.errorHelperText}
                                        </FormHelperText>
                                    }
                            </FormControl>
        </React.Fragment>)
    }
}

interface ISignupPaymentProps {
    publicService: PublicService
}

@observer
export class SignupComponentWithPayment extends React.Component<ISignupPaymentProps, ILoginState> {

    store = new SignupStore(process.env.REACT_APP_ASSIGN_RANDOM_PHONE_NUMBER === 'true');

    @observable showPassword: boolean = false;
    @observable recaptchaToken_v2: string | null = null;
    @observable acceptedTnC: boolean = false;
    @observable requestState: requestStateType = 'notstarted';

    timer: NodeJS.Timer | undefined;
    // stripe: Stripe | null = null;
    // elements: any;
    private captchaRef = React.createRef<ReCAPTCHA>()
    unappliedPromoCode: string | null = null;

    constructor(props: ISignupPaymentProps) {
        super(props);
        makeObservable(this);
        (window as any)._cophone = {
            signupComponent: this
        }
        reaction(() => [this.store.product, this.store.promoCode], async () => {
            if (this.store.product || this.store.promoCode) {
                const res = await this.props.publicService.presubscribe({
                    stripeCustomerId: this.store.stripeCustomerId,
                    subscriptionId: this.store.subscriptionId,
                    priceId: this.store.product.setPrice.id,
                    productId: this.store.product.id,
                    promoCode: this.store.promoCode,
                    countryCode: this.store.countryCode
                })
                runInAction(() => {
                    this.store.stripeCustomerId = res.stripeCustomerId
                    this.store.subscriptionId = res.subscriptionId
                    this.store.price = res.price.amount
                    this.store.currency = res.price.currency
                })
            }
        })
    }

    @bind
    handleSubmit(ev: any) {
        console.log('Submit');
        ev.preventDefault();
    }

    @computed
    private get readyToSubmit() {
        return this.recaptchaToken_v2 && this.acceptedTnC && this.store.ready;
    }

    @bind
    private async doSignup() {

        try {
            runInAction(() => this.requestState = 'verifying');

            if (this.store.subscriptionId && this.store.email && this.store.password && this.store.stripeCustomerId) {
                const subscribeResult = await this.props.publicService.subscribe(
                    {
                        subscriptionId: this.store.subscriptionId,
                        acceptedTnC: this.acceptedTnC,
                        password: this.store.password,
                        username: this.store.email,
                        phoneNumber: this.store.phoneNumber,
                        priceId: this.store.product.setPrice.id,
                        productId: this.store.product.id,
                        recaptchaToken_v2: this.recaptchaToken_v2!,
                        promotionCode: this.store.promoCode,
                        stripeCustomerId: this.store.stripeCustomerId,
                        countryCode: this.store.countryCode
                    }
                )

                console.log(subscribeResult);
                runInAction(() => this.requestState = 'ok');

                if (subscribeResult.accountCreated === true) {
                    runInAction(() => this.store.accountCreated = true)
                } else {
                    if (!this.store.stripe || !this.store.elements) {
                        // Stripe.js has not yet loaded.
                        // Make sure to disable form submission until Stripe.js has loaded.
                        return;
                    }                

                    const cardElement = this.store.elements.getElement(CardElement)
                    if (cardElement) {
                        runInAction(async () =>
                            this.store.paymentIntentResult = await this.store.stripe!.confirmCardPayment(subscribeResult.clientSecret!, {
                                payment_method: {
                                card: cardElement,
                                billing_details: {
                                    name: this.store.name,
                                },
                                },
                            })
                        )
                        // console.log('paymentIntentResult', paymentIntentResult)
                        // if (paymentIntentResult.error) {
                        //     throw new Error('An error occured processing your payment')
                        // } else {
                        //     if (paymentIntentResult.paymentIntent.status === 'succeeded') {

                        //     }
                        // }
                    }

                    // window.location.href = 'confirm-email';
                } 
            }
        } catch (e) {
            runInAction(() => this.requestState = 'error');
            console.error(e);
            setTimeout(() => runInAction(() => this.requestState = 'notstarted'), 5000)
        } finally {
            this.captchaRef.current?.reset()
        }
    }

    @bind
    onVerify(token: string): void | Promise<void> {
        runInAction(() => this.recaptchaToken_v2 = token);
    }

    @bind
    goToLogin() {
        window.location.href = 'login'
    }

    render() {
        return <React.Fragment>
            <form onSubmit={this.handleSubmit}>
                {/* <GoogleReCaptchaProvider reCaptchaKey={this.props.recaptchaSiteKey}>
                    <GoogleReCaptcha
                        onVerify={this.onVerify}
                        refreshReCaptcha={this.refreshReCaptcha} /> */}
                    <Box display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="100vh">
                        <Stack sx={{ minWidth: '20%', maxWidth: '80%' }} spacing={2}>
                            <CommonAppTitle/>
                            <Divider>Login details</Divider>
                            <LoginDetails store={this.store} />
                            <Divider>Product</Divider>
                            <ProductSelect store={this.store} publicService={this.props.publicService} />
                            <Divider>Secure payment info</Divider>
                            { this.store.product && <PaymentInfo publicService={this.props.publicService} store={this.store} ></PaymentInfo> }
                            <Divider>Legal</Divider>
                            <FormControlLabel componentsProps={{ typography: { fontSize: '8' } }} control={<Checkbox onChange={(ev) => runInAction(() => this.acceptedTnC = ev.target.checked)} size="medium" />}
                                label={<div>
                                    <span>I accept the </span>
                                    <Link href={"/terms"}>terms of use</Link>
                                    <span> and </span>
                                    <Link href={'/privacy'}>privacy policy</Link>
                                </div>} />
                            <ReCAPTCHA sitekey={this.props.publicService.recaptchaSiteKey_v2} onChange={(token) => runInAction(() => this.recaptchaToken_v2 = token)} ref={this.captchaRef}></ReCAPTCHA>

                            {(this.requestState === 'ok') && <Alert severity="success" variant="outlined">OK</Alert>}
                            {(this.requestState === 'error') && <Alert severity="error" variant="outlined">Something went wrong. Please try again!</Alert>}
                            {(this.requestState === 'notstarted') && <Button type="submit" color="secondary" disabled={!this.readyToSubmit} variant="contained" onClick={this.doSignup}>Signup</Button>}
                            {(this.requestState === 'verifying') && <div style={{ display: 'flex', justifyContent: 'center' }}><LinearProgress /></div>}
                            <Paper key="info" elevation={0}>
                                <Typography variant="body2" component="h3" align="center" justifyContent={"center"}>
                                    Already have an account? <Link href='login'>Login</Link> instead.
                                </Typography>
                            </Paper>
                        </Stack>

                    </Box>
                {/* </GoogleReCaptchaProvider> */}
            </form>
            <Dialog
                open={ this.store.readyToLogin }
                onClose={this.goToLogin}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Thank you!"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Thank you for subscribing to our service. You can now login.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.goToLogin} autoFocus>
                    To login
                </Button>
                </DialogActions>
            </Dialog>
            <WaitingListDialog open={gateUsers} publicService={this.props.publicService}></WaitingListDialog>
            </React.Fragment>
    }
}
